<template>
  <div class="container pr" v-if="isFinsh">
    <!--    header-->
    <div class="headerView">
      <!-- 坐标城市 -->
      <div
        class="address pa u-flex u-col-center u-row-between"
        @click="handleClickPosition"
        v-cloak
      >
        <img class="icon-position" src="../../assets/images/icon_loction.png" />
        <span>{{ addressPositionName }}</span>
      </div>
      <div class="backBtn" @click="backBtnDidAction"></div>

      <van-progress :percentage="progressCount.total" class="progress" />

      <div class="text-wrapper_5 flex-col justify-between">
        <div class="text-wrapper_6">
          <span class="text_7">预计可借金额：</span>
          <span class="text_8">{{ 200000 }}元</span>
        </div>
        <span class="paragraph_1">
          信息仅用于核验,合作机构评估,您的信息将被加密保护。可借金额不等于实际借款金额
        </span>
      </div>
      <div class="register-form">
        <!-- 姓名 -->
        <van-cell-group
          class="inp-elem-box"
          v-show="formShowControl.isShowName"
        >
          <van-field
            v-model.trim="form.userName"
            maxlength="4"
            label="真实姓名"
            class="inp-elem"
            placeholder="请输入您的真实姓名"
            @blur="handleBlur(0)"
            clearable
          />
        </van-cell-group>

        <!-- 年龄 -->
        <van-cell-group class="inp-elem-box" v-show="formShowControl.isShowAge">
          <van-field
            v-model.trim="form.age"
            label="真实年龄"
            type="digit"
            maxlength="2"
            class="inp-elem"
            placeholder="年龄在22岁-55岁均可申请"
            @blur="handleBlur(1)"
            clearable
          />
        </van-cell-group>

        <!-- 身份证号 -->
        <van-cell-group
          class="inp-elem-box"
          v-show="formShowControl.isShowIdCard"
        >
          <van-field
            v-model.trim="form.idCard"
            label="身份证号"
            type="digit"
            maxlength="19"
            class="inp-elem"
            placeholder="请输入身份证号"
            @blur="handleBlur(2)"
            clearable
          />
        </van-cell-group>
      </div>
    </div>
    <!-- 性别 -->
    <div class="sex" v-show="formShowControl.isShowSex">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">性别</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowSex"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseSex(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
    <!-- 借多少(元) -->
    <div class="zhimafen" v-show="formShowControl.isShowLoanMount">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">借多少(元)</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowLoanMount"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseLoanMount(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 借多久(月) -->
    <div class="zhimafen" v-show="formShowControl.isShowLoanTime">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">借多久(月)</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowLoanTime"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseLoanTime(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 用途 -->
    <div class="zhimafen" v-show="formShowControl.isShowLoanPurpose">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">借款用途</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowLoanPurpose"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseLoanPurpose(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 芝麻分(单选) -->
    <div class="zhimafen" v-show="formShowControl.isShowAuthFields">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">芝麻分(单选)</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowAuthFields"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseCredit(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 京东白条(单选) -->
    <div class="zhimafen" v-show="formShowControl.isShowJdIousFields">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">京东白条(单选)</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowJdIousFields"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseJddept(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 教育程度(单选) -->
    <div class="zhimafen" v-show="formShowControl.isShowEducation">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">教育程度(单选)</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowEducation"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseEducation(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 资产信息(多选) -->
    <div class="zhimafen" v-show="formShowControl.isShowAssetsFields">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">资产信息(多选)</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowAssetsFields"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseAssetsInfo(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 婚姻状况(单选) -->
    <div class="zhimafen" v-show="formShowControl.isShowMarry">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">婚姻状况(单选)</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowMarry"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseMarry(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 职业身份(单选) -->
    <div class="zhimafen" v-show="formShowControl.isShowProfession">
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">职业身份(单选)</div>
        <van-row :gutter="15">
          <van-col
            v-for="(item, index) in formItemData.isShowProfession"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseProfession(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 提交 -->
    <button class="btn-submit" @click="handleSubmit">获取额度</button>
    <div class="bottom_view">
      <img
        class="bottom_icon"
        src="../../assets/images/model8/bottom_icon.png"
      />

      <span class="text-group_1">
        信息仅用于核验,合作机构评估,您的信息将被加密保护
      </span>
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />

    <!-- 工作城市下拉选 -->
    <van-popup
      v-model="showPickerCity"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        show-toolbar
        title="请选择工作城市"
        :columns="areaList"
        @change="onCityChange"
        @confirm="onAreaConfirm"
        @cancel="showPickerCity = false"
        value-key="localName"
      />
    </van-popup>

    <!-- 头条表单渠道注册的同意协议继续下一步的弹窗 -->
    <van-popup
      v-model="toutiaoShow"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
      :close-on-click-overlay="false"
      :style="{ height: '90%' }"
    >
      <div class="toutiao-show-con">
        <p class="toutiao-pup-title">
          融e钱包将根据您的资质,为您匹配专业的贷款咨询公司（包含:
          <small>{{ companys }}</small>
          ），请查阅
          <span @click.stop="showProp('xxsq')">《个人信息共享授权书》</span>和
          <span @click.stop="showProp('zqgz')">《知情告知书》</span>
        </p>
        <div class="toutiao-pup-protocol u-text-center">
          <div class="pr" :class="xxsqHeightFlag ? '' : 'expand-height'">
            <h4>《个人信息共享授权书》</h4>
            <div class="protocol-content" v-html="xxsq"></div>
            <van-cell
              v-if="xxsqHeightFlag"
              class="pa islink"
              title="完整协议"
              is-link
              :border="false"
              :clickable="true"
              @click="handleFullAgreement('xxsq')"
            />
          </div>
          <div class="pr" :class="zqgzHeightFlag ? '' : 'expand-height'">
            <h4>《知情告知书》</h4>
            <div class="protocol-content" v-html="zqgz" />
            <van-cell
              v-if="zqgzHeightFlag"
              class="pa islink"
              title="完整协议"
              is-link
              :border="false"
              :clickable="true"
              @click="handleFullAgreement('zqgz')"
            />
          </div>
        </div>
        <div class="go-on pa u-text-center">
          <p @click="handleGoOn">同意协议并继续</p>
        </div>
      </div>
    </van-popup>

    <!-- 《个人信息共享授权书》和《知情告知书》局部弹窗 -->
    <van-popup
      v-model="showProtocol"
      round
      :style="{
        width: '80%',
        height: '70%',
      }"
      @close="handleClose"
    >
      <div class="show-protocol-con">
        <h4 class="u-text-center">{{ loaclTitle }}</h4>
        <div class="show-protocol-info protocol-content" v-html="showContent" />
        <div
          class="show-protocol-btn u-text-center"
          @click="showProtocol = false"
        >
          关闭
        </div>
      </div>
    </van-popup>

    <!-- 弹窗 -->
    <div class="box_8 flex-col" v-show="showPopProduct" v-if="this.product">
      <div class="section_11 flex-col">
        <div class="box_9 flex-row justify-between">
          <van-checkbox v-model="rpChecked" shape="square" icon-size="17px">
          </van-checkbox>
          <div class="text-wrapper_10">
            <span class="text_20">我已阅读并同意</span>
            <span class="text_21" @click.stop="handleProtocol(9)"
              >《用户个人信息共享授权协议》</span
            >
          </div>
        </div>
        <!-- 申请按钮 -->
        <img
          class="box_10 flex-row"
          src="../../assets/images/popup_product_btn.png"
          @click="rpApplyBtndDidAction"
        />

        <div class="box_11 flex-col">
          <div class="box_12 flex-row">
            <div class="group_10 flex-col"></div>
          </div>
          <div class="image-wrapper_1 flex-row">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc27f0cd2443d0f48a8e14c965e8e368fbdaf7df71af59584e8880966b3065b75"
            />
          </div>
        </div>
        <div class="box_13 flex-col">
          <div class="section_12 flex-row justify-between">
            <div
              class="icon_1 flex-col"
              :style="{
                backgroundImage:
                  'url(' +
                  (this.product.logo
                    ? this.product.logo
                    : this.product.productPlaceHolder) +
                  ')',
                backgroundSize: '27px 27px',
                backgroundRepeat: 'no-repeat',
              }"
            ></div>
            <span class="text_23" v-if="this.product">{{
              this.product.productName
            }}</span>
          </div>
          <div class="text-wrapper_12 flex-row justify-between">
            <span class="text_24">最高额度(元）</span>
            <span class="text_25">综合日利率</span>
          </div>
          <div class="section_13 flex-row justify-between">
            <div class="text-group_6 flex-col justify-between">
              <span class="text_26">{{ this.product.loanAmount }}</span>
            </div>
            <div class="text-group_7 flex-col justify-between">
              <span class="text_27">{{ this.product.loanRate }}</span>
            </div>
          </div>
          <div class="text-wrapper_13 flex-col">
            <span class="text_28">已为您匹配高额产品</span>
          </div>
        </div>
        <div class="box_14 flex-col"></div>
        <div class="section_14 flex-col">
          <div class="icon_2 flex-col" @click="reCloseBtnDidAction"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="././public/js/base.js"></script>
<script>
import SharePop from "@/components/SharePop";
import myBMap from "../../utils/myBMap";
import * as API_Common from "@/api/common.js";
import { Dialog } from "vant";

window.onpageshow = function (event) {
  if (
    localStorage.getItem("isSubimit") &&
    !localStorage.getItem("isClickAuth")
  ) {
    if (event.persisted) {
      window.location.reload();
    }
  }
};
export default {
  name: "RegisterEntryModel8",
  components: {
    SharePop,
  },
  data() {
    return {
      isFinsh: false,
      positionStatus: false,
      showPopProduct: false,
      rpChecked: true,
      logo: "",
      address: {
        province: "",
        city: "",
      },
      areaList: [{ values: [] }, { values: [] }],
      showPickerCity: false,
      redirect: this.$route.query.redirect,
      created: this.$route.query.created,
      code: this.$route.query.code,
      tid: this.$route.query.tid,
      source: this.$route.query.source,
      isShowView: false,
      progress: 0,
      form: {
        channelNo: this.$route.query.source,
        userName: "",
        idCard: "",
        age: "",
        sex: "", //1：男，2：女
        jddept: "", //白条
        assets: [], //资产信息
        loanAmount: "", //借多少
        loanPeriod: "", //借多久
        loanPurpose: "", //借款用途
        credit: "", //芝麻分
        marriage: "", //婚姻
        education: "", //学历
        gpsCityName: "",
        cityId: "",
        provinceId: "",
        profession: "",
      },
      //进度条每项占比
      progressCount: {
        total: 0,
        average: 0, //平均每项
        name: 0,
        age: 0,
        idCard: 0,
        jddept: 0,
        loanAmount: 0,
        loanPeriod: 0,
        loanPurpose: 0,
        credit: 0,
        profession: 0,
        marriage: 0,
        education: 0,
        assets: 0,
        sex: 0,
      },

      product: {
        //产品名字
        productName: "",
        //产品icon
        logo: "",
        //产品icon占位图
        productPlaceHolder:
          "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8d4f87aba9e348a0b8a48a06a914d47e_mergeImage.png",
        //产品最高额度
        loanAmount: "",
        //产品利率
        loanRate: "",
      },
      productList: [],
      stepLineMove: false,
      showPop: false,
      formShowControl: {
        isShowName: false,
        isShowIdCard: false,
        isShowLoanMount: false,
        isShowLoanTime: false,
        isShowLoanPurpose: false,
        isShowAuthFields: false,
        isShowJdIousFields: false,
        isShowEducation: false,
        isShowAssetsFields: false,
        isShowMarry: false,
        isShowSex: false,
      },
      formItemData: {
        isShowLoanMount: [
          { dictValue: 1, dictLabel: "1-5万" },
          { dictValue: 2, dictLabel: "5-10万" },
          { dictValue: 3, dictLabel: "10-20万" },
          { dictValue: 4, dictLabel: "20万以上" },
        ],
        //借多久(月)
        isShowLoanTime: [
          { dictValue: 1, dictLabel: "3" },
          { dictValue: 2, dictLabel: "6" },
          { dictValue: 3, dictLabel: "12" },
        ],
        //借款用途
        isShowLoanPurpose: [
          { dictValue: 1, dictLabel: "日常消费" },
          { dictValue: 2, dictLabel: "装修贷款" },
          { dictValue: 3, dictLabel: "医疗贷款" },
          { dictValue: 4, dictLabel: "旅游贷款" },
          { dictValue: 5, dictLabel: "买车贷款" },
        ],
        isShowSex: [
          { dictValue: 1, dictLabel: "男" },
          { dictValue: 2, dictLabel: "女" },
        ],
        //芝麻分(单选)
        isShowAuthFields: [
          { dictValue: 1, dictLabel: "650以下" },
          { dictValue: 2, dictLabel: "650-700" },
          { dictValue: 3, dictLabel: "700以上" },
          { dictValue: 4, dictLabel: "无" },
        ],
        //京东白条(单选)
        isShowJdIousFields: [
          { dictValue: 1, dictLabel: "5000以下" },
          { dictValue: 2, dictLabel: "5000以上" },
          { dictValue: 0, dictLabel: "无" },
        ],
        //教育程度(单选)
        isShowEducation: [
          { dictValue: 1, dictLabel: "硕士及以上" },
          { dictValue: 2, dictLabel: "本科" },
          { dictValue: 3, dictLabel: "大专" },
          { dictValue: 4, dictLabel: "高中" },
          { dictValue: 5, dictLabel: "中专" },
          { dictValue: 6, dictLabel: "初中及以下" },
        ],
        //资产信息(多选)
        isShowAssetsFields: [
          { dictValue: 1, dictLabel: "房产" },
          { dictValue: 2, dictLabel: "车产" },
          { dictValue: 3, dictLabel: "公积金" },
          { dictValue: 4, dictLabel: "社保" },
          { dictValue: 5, dictLabel: "商业保险" },
          { dictValue: 6, dictLabel: "企业主" },
        ],
        //婚姻状况(单选)
        isShowMarry: [
          { dictValue: 1, dictLabel: "未婚" },
          { dictValue: 2, dictLabel: "已婚" },
          { dictValue: 3, dictLabel: "离异" },
        ],
        //职业身份(单选)
        isShowProfession: [
          { dictValue: 1, dictLabel: "上班族" },
          { dictValue: 2, dictLabel: "个体工商户" },
          { dictValue: 3, dictLabel: "自由职业者" },
          { dictValue: 4, dictLabel: "退休/待业/ 无业" },
          { dictValue: 5, dictLabel: "在校学生" },
          { dictValue: 6, dictLabel: "农民" },
          { dictValue: 7, dictLabel: "私营业主" },
        ],
      },
      regTest: {
        regUserName: /^[\u4e00-\u9fa5]{2,6}$/,
        regIdcard:
          /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      },
      h5LoanRemindDesc: "",
      toutiaoShow: false,
      xxsq: "",
      xxsqHeightFlag: true,
      zqgz: "",
      zqgzHeightFlag: true,
      showContent: "",
      showProtocol: false,
      companys: "",
      loaclTitle: "",
      isClickSubmit: false, //点击提交按钮不弹出alertview
      showBackBtn: false, //无产品不显示返回按钮
      timerUpdate: undefined,
      timerUpdate5: undefined,
      timerUpdate10: undefined,
      timerUpdate30: undefined,
      timerUpdate60: undefined,
    };
  },

  mounted() {
    let isWeixin = this.isWeixin();
    if (
      isWeixin &&
      this.form.channelNo != "wxgzh" &&
      this.form.channelNo != "wxgzh1"
    ) {
      this.showPop = true;
    }
    this.checkPosition();
    setTimeout(() => {
      this.stepLineMove = true;
    }, 10);
    localStorage.removeItem("isPopupProduct");
    localStorage.removeItem("isPushAuth");
    this.queryFormItem();
    this.getLogo();
    this.getRiskWarningText();
    this.getProtocol();
    this.requestPopupProduct();
    if (
      localStorage.getItem("isSubimit") &&
      !localStorage.getItem("isClickAuth")
    ) {
      this.submitFormNextGoPage();
    }
    this.channelIncomingControl();
    this.handleUserResidenceTime();
  },
  computed: {
    addressPositionName() {
      if (this.positionStatus) {
        return `${this.address.province} ${this.address.city}`;
      } else {
        if (this.form.gpsCityName) {
          return this.form.gpsCityName;
        } else {
          // this.$toast("定位失败");
          return "定位失败";
        }
      }
    },
  },
  methods: {
    checkPosition() {
      if (navigator.geolocation) {
        this.initBMap();
      } else {
        this.$toast("当前浏览器不支持定位，请手动选择定位");
        this.manualPosition();
      }
    },

    initBMap() {
      myBMap.init().then((BMap) => {
        let that = this;
        let geolocation = new BMap.Geolocation();

        geolocation.enableSDKLocation();
        geolocation.getCurrentPosition(
          function (r) {
            if (r) {
              /*
              关于this.getStatus()返回状态码示例说明
              BMAP_STATUS_SUCCESS 检索成功。对应数值“0”
              BMAP_STATUS_CITY_LIST 城市列表。对应数值“1”
              BMAP_STATUS_UNKNOWN_LOCATION 位置结果未知。对应数值“2”
              BMAP_STATUS_UNKNOWN_ROUTE 导航结果未知。对应数值“3”
              BMAP_STATUS_INVALID_KEY 非法密钥。对应数值“4”
              BMAP_STATUS_INVALID_REQUEST 非法请求。对应数值“5”
              BMAP_STATUS_PERMISSION_DENIED 没有权限。对应数值“6”
              BMAP_STATUS_SERVICE_UNAVAILABLE 服务不可用。对应数值“7”
              BMAP_STATUS_TIMEOUT 超时。对应数值“8”
            */
              if (this.getStatus() === 0 && r.address.city) {
                that.positionStatus = true;
                that.address.province = r.address.province;
                that.address.city = r.address.city;
                that.form.gpsCityName = that.address.city;
              } else {
                that.$toast.fail("定位失败，请手动选择城市");
                that.manualPosition();
              }
            }
          },
          function () {
            that.$toast.fail("定位失败，请手动选择城市");
            that.manualPosition();
          },
          { province: "baidu" }
        );
      });
    },

    manualPosition() {
      this.positionStatus = false;
    },

    handleClickPosition() {
      if (!this.positionStatus) {
        this.getArea(0, 0);
        this.showPickerCity = true;
      }
    },
    //姓名 年龄校验
    handleBlur(index) {
      switch (index) {
        case 0:
          if (this.form.userName !== "") {
            if (!this.regTest.regUserName.test(this.form.userName)) {
              this.$toast("请输入正确的姓名");
              this.amount.name = 0;
              this.progressCount.name = 0;
            } else {
              this.progressCount.name = this.progressCount.average;
              this.progressCalculate();
            }
          } else {
            this.amount.name = 0;
            this.progressCount.name = 0;
          }
          break;
        case 1:
          if (!this.form.age || this.form.age == "") {
            this.form.age = "";
            this.$toast("请输入年龄");
            this.amount.age = 0;
            this.progressCount.age = 0;
          } else if (
            parseInt(this.form.age) < 22 ||
            parseInt(this.form.age) > 55
          ) {
            this.$toast("您的年龄不符合申请要求");
            this.form.age = "";
            this.amount.age = 0;
            this.progressCount.age = 0;
          } else {
            this.progressCount.age = this.progressCount.average;
            this.progressCalculate();
          }
          break;
        case 2:
          if (this.form.idCard !== "") {
            if (!this.regTest.regIdcard.test(this.form.idCard)) {
              this.$toast("请输入正确的身份证号码");
            } else {
              this.progressCount.idCard = this.progressCount.average;
              this.progressCalculate();
            }
          }
          break;
      }
      this.progressCalculate();
    },
    //计算进度&可借金额
    progressCalculate() {
      this.progressCount.total = 0;
      this.progressCount.total =
        this.progressCount.name +
        this.progressCount.age +
        this.progressCount.assets +
        this.progressCount.idCard +
        this.progressCount.loanAmount +
        this.progressCount.loanPeriod +
        this.progressCount.loanPurpose +
        this.progressCount.credit +
        this.progressCount.profession +
        this.progressCount.jddept +
        this.progressCount.marriage +
        this.progressCount.sex +
        this.progressCount.education;
      if (this.progressCount.total > 95 && this.progressCount.total < 100) {
        this.progressCount.total = 100;
      }
      if (this.progressCount.total > 100) {
        this.progressCount.total = 100;
      }
    },
    initProgressCalculate() {
      let optionsCount = 0;
      if (this.formShowControl.isShowName) {
        optionsCount++;
      }
      if (this.formShowControl.isShowIdCard) {
        optionsCount++;
      }
      if (this.formShowControl.isShowAge) {
        optionsCount++;
      }
      if (this.formShowControl.isShowSex) {
        optionsCount++;
      }
      if (this.formShowControl.isShowAuthFields) {
        optionsCount++;
      }
      if (this.formShowControl.isShowAssetsFields) {
        optionsCount++;
      }
      if (this.formShowControl.isShowJdIousFields) {
        optionsCount++;
      }
      if (this.formShowControl.isShowLoanMount) {
        optionsCount++;
      }
      if (this.formShowControl.isShowLoanTime) {
        optionsCount++;
      }
      if (this.formShowControl.isShowLoanPurpose) {
        optionsCount++;
      }
      if (this.formShowControl.isShowEducation) {
        optionsCount++;
      }
      if (this.formShowControl.isShowMarry) {
        optionsCount++;
      }
      if (this.formShowControl.isShowProfession) {
        optionsCount++;
      }
      this.progressCount.average = Math.round(100 / optionsCount);
    },
    //查询需要填写的进件资料
    channelIncomingControl() {
      API_Common.channelIncomingControl(this.form.channelNo).then((res) => {
        if (res.code == 200) {
          this.formShowControl = res.data;
          this.initProgressCalculate();
        }
      });
    },
    handleSubmit() {
      if (!this.positionStatus && !this.form.cityId) {
        this.$toast("请选择您所在的城市");
        return;
      } else if (this.form.userName === "" && this.formShowControl.isShowName) {
        this.$toast("请输入姓名");
        return;
      } else if (
        !this.regTest.regUserName.test(this.form.userName) &&
        this.formShowControl.isShowName
      ) {
        this.$toast("请输入合法的姓名");
        return;
      }
      if (this.form.age === "" && this.formShowControl.isShowAge) {
        this.$toast("请输入年龄");
        return;
      }
      if (this.form.idCard === "" && this.formShowControl.isShowIdCard) {
        this.$toast("请输入身份证号");
        return;
      } else if (
        !this.regTest.regIdcard.test(this.form.idCard) &&
        this.formShowControl.isShowIdCard
      ) {
        this.$toast("请输入合法的身份证号");
        return;
      }

      if (this.form.sex === "" && this.formShowControl.isShowSex) {
        debugger;
        this.$toast("请选择性别");
        return;
      }

      if (this.form.loanAmount === "" && this.formShowControl.isShowLoanMount) {
        this.$toast("请选择借多少");
        return;
      }
      if (this.form.loanPeriod === "" && this.formShowControl.isShowLoanTime) {
        this.$toast("请选择借多久");
        return;
      }
      if (
        this.form.loanPurpose === "" &&
        this.formShowControl.isShowLoanPurpose
      ) {
        this.$toast("请选择借款用途");
        return;
      }

      if (this.form.credit === "" && this.formShowControl.isShowAuthFields) {
        this.$toast("请选择芝麻分");
        return;
      }
      if (!this.form.jddept && this.formShowControl.isShowJdIousFields) {
        this.$toast("请选择京东白条");
        return;
      }
      this.formItemData.isShowAssetsFields.map((i) => {
        if (i.active) {
          this.form.assets.push(i.dictValue);
        }
      });
      if (
        this.form.assets.length === 0 &&
        this.formShowControl.isShowAssetsFields
      ) {
        this.$toast("请至少选择一项资产");
        return;
      }
      if (this.form.education === "" && this.formShowControl.isShowEducation) {
        this.$toast("请选择教育程度");
        return;
      }
      if (this.form.marriage === "" && this.formShowControl.isShowMarry) {
        this.$toast("请选择婚姻状况");
        return;
      }
      if (
        this.form.profession === "" &&
        this.formShowControl.isShowProfession
      ) {
        this.$toast("请选择婚姻状况");
        return;
      }

      let datas = {
        ...this.form,
        ...{
          userName: this.form.userName,
        },
      };
      let fun = "submitAnEntry";
      API_Common[fun](datas).then((res) => {
        if (res.code === 200 || res.msg == "insertExisit") {
          clearTimeout(this.timerUpdate);
          clearTimeout(this.timerUpdate5);
          clearTimeout(this.timerUpdate10);
          clearTimeout(this.timerUpdate30);
          clearTimeout(this.timerUpdate60);
          API_Common.delayModel8SUpdate(this.source, 0, this.tid, 4);
          if (
            res.data &&
            res.data.isAlert &&
            res.data.isAlert === true &&
            this.form.channelNo != "TUOTIAO_FORM_3"
          ) {
            this.toutiaoShow = true;
            this.companys = res.data.apiNames.join("、");
            this.xxsq = this.xxsq.replace("%s", this.companys);
          } else {
            if (this.product.productName) {
              localStorage.setItem("isSubimit", "YES");
              this.isClickSubmit = true;
              this.showPopProduct = true;
            } else {
              this.submitFormNextGoPage();
            }
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    queryFormItem() {
      API_Common.queryFormItem().then((res) => {
        if (res.code == 200) {
          this.formItemData.credit = res.data.credit;
          this.computedActive(this.formItemData);
          this.isFinsh = true;
        } else {
          this.$toast(res.msg);
        }
      });
    },

    getLogo() {
      API_Common.getRegister2Logo(this.form.channelNo).then((res) => {
        if (res.code == 200) {
          this.logo = res.data;
        } else {
          this.$toast(res.msg);
        }
      });
    },

    getRiskWarningText() {
      API_Common.queryRiskWarning(this.form.channelNo).then((res) => {
        if (res.code == 200) {
          const { h5LoanRemindDesc } = res.data;
          this.h5LoanRemindDesc = h5LoanRemindDesc.replace(/[;；]/g, "\n");
        }
      });
    },

    computedActive(objs) {
      Object.keys(objs).forEach((key) => {
        let arr = objs[key];
        arr.map((item) => {
          item.active = false;
        });
      });
    },

    handleChooseSex(index) {
      this.formItemData.isShowSex.map((item) => {
        item.active = false;
      });
      this.formItemData.isShowSex[index].active = true;
      this.form.sex = this.formItemData.isShowSex[index].dictValue;
      this.$forceUpdate();
      this.progressCount.sex = this.progressCount.average;
      this.progressCalculate();
    },
    //借多少钱
    handleChooseLoanMount(index) {
      this.formItemData.isShowLoanMount.map((item) => {
        item.active = false;
      });
      this.formItemData.isShowLoanMount[index].active = true;
      this.form.loanAmount = this.formItemData.isShowLoanMount[index].dictLabel;
      this.$forceUpdate();
      this.progressCount.loanAmount = this.progressCount.average;
      this.progressCalculate();
    },
    //借多久
    handleChooseLoanTime(index) {
      this.formItemData.isShowLoanTime.map((item) => {
        item.active = false;
      });
      this.formItemData.isShowLoanTime[index].active = true;
      this.form.loanPeriod = this.formItemData.isShowLoanTime[index].dictLabel;
      this.$forceUpdate();
      this.progressCount.loanPeriod = this.progressCount.average;
      this.progressCalculate();
    },

    //用途
    handleChooseLoanPurpose(index) {
      this.formItemData.isShowLoanPurpose.map((item) => {
        item.active = false;
      });
      this.formItemData.isShowLoanPurpose[index].active = true;
      this.form.loanPurpose =
        this.formItemData.isShowLoanPurpose[index].dictLabel;
      this.$forceUpdate();
      this.progressCount.loanPurpose = this.progressCount.average;
      this.progressCalculate();
    },
    //芝麻分
    handleChooseCredit(index) {
      this.formItemData.isShowAuthFields.map((item) => {
        item.active = false;
      });
      this.formItemData.isShowAuthFields[index].active = true;
      this.form.credit = this.formItemData.isShowAuthFields[index].dictLabel;
      this.$forceUpdate();
      this.progressCount.credit = this.progressCount.average;
      this.progressCalculate();
    },
    //京东白条
    handleChooseJddept(index) {
      this.formItemData.isShowJdIousFields.map((item) => {
        item.active = false;
      });
      this.formItemData.isShowJdIousFields[index].active = true;
      this.form.jddept = this.formItemData.isShowJdIousFields[index].dictValue;
      this.$forceUpdate();
      this.progressCount.jddept = this.progressCount.average;
      this.progressCalculate();
    },

    //教育程度
    handleChooseEducation(index) {
      this.formItemData.isShowEducation.map((item) => {
        item.active = false;
      });
      this.formItemData.isShowEducation[index].active = true;
      this.form.education = this.formItemData.isShowEducation[index].dictLabel;
      this.$forceUpdate();
      this.progressCount.education = this.progressCount.average;

      this.progressCalculate();
    },
    //资产信息
    handleChooseAssetsInfo(index) {
      if (this.formItemData.isShowAssetsFields[index].active) {
        let arr = this.formItemData.isShowAssetsFields.filter(
          (item) => item.active
        );

        if (arr.length > 1) {
          this.formItemData.isShowAssetsFields[index].active = false;
        } else {
          this.$toast.fail("至少保留一项");
        }
      } else {
        this.formItemData.isShowAssetsFields[index].active = true;
      }
      this.progressCount.assets = this.progressCount.average;
      this.progressCalculate();
      this.$forceUpdate();
    },
    //婚姻
    handleChooseMarry(index) {
      this.formItemData.isShowMarry.map((item) => {
        item.active = false;
      });
      this.formItemData.isShowMarry[index].active = true;
      this.form.marriage = this.formItemData.isShowMarry[index].dictLabel;
      this.$forceUpdate();
      this.progressCount.marriage = this.progressCount.average;
      this.progressCalculate();
    },
    //职业
    handleChooseProfession(index) {
      this.formItemData.isShowProfession.map((item) => {
        item.active = false;
      });
      this.formItemData.isShowProfession[index].active = true;
      this.form.profession =
        this.formItemData.isShowProfession[index].dictLabel;
      this.$forceUpdate();
      this.progressCount.profession = this.progressCount.average;
      this.progressCalculate();
    },

    handlePopChange(show) {
      this.showPop = show;
    },

    getArea(parentId, index) {
      API_Common.getRegions(parentId).then((res) => {
        if (200 === res.code) {
          this.areaList[index].values = [{ localName: "请选择" }, ...res.data];
          this.areaList = [...this.areaList]; //更新areaList
          this.loading = false;
        } else {
          this.$toast.fail("获取省份失败");
        }
      });
    },

    onCityChange(picker, values, index) {
      if (values[index].localName === "请选择" && index !== 1) {
        this.areaList[1].values = [{ localName: "请选择" }];
      } else {
        if (!index) {
          this.getArea(values[0].id, index + 1); //传参 参数为上层选择的地区的id
        }
      }
    },

    onAreaConfirm(picker) {
      if (
        picker[0].localName === "请选择" ||
        picker[1].localName === "请选择"
      ) {
        this.$toast.fail("请选择完整的省市");
        return false;
      } else {
        this.form.gpsCityName = `${picker[0].localName} ${picker[1].localName}`;
        this.form.provinceId = picker[0].id;
        this.form.cityId = picker[1].id;
        this.showPickerCity = false;
      }
    },

    showProp(type) {
      this.showContent = this[type];
      this.showProtocol = true;
      this.toutiaoShow = false;
      this.loaclTitle =
        type === "zqgz" ? "《知情告知书》" : "《个人信息共享授权书》";
    },

    handleClose() {
      this.showContent = "";
      this.loaclTitle = "";
      this.toutiaoShow = true;
    },

    getProtocol() {
      Promise.all([
        API_Common.getContent({ pageType: 11 }),
        API_Common.getContent({ pageType: 10 }),
      ]).then((res) => {
        this.xxsq = res[0].data.content;
        this.zqgz = res[1].data.content;
      });
    },

    handleFullAgreement(flag) {
      this[`${flag}HeightFlag`] = false;
    },
    submitFormNextGoPage() {
      localStorage.removeItem("isSubimit");
      localStorage.removeItem("isClickAuth");
      this.showPopProduct = false;
      this.$router.push({
        name: "stateWatingModel8",
        query: {
          source: this.form.channelNo,
          code: this.code,
        },
      });
    },
    handleGoOn() {
      this.submitFormNextGoPage();
    },
    //     点击推荐产品立即申请
    rpApplyBtndDidAction() {
      if (!this.rpChecked) {
        this.$toast("请勾选同意协议及政策");
        return;
      }
      //跳转到产品详情
      // window.location.href = this.product.linkUrl;
      // this.showPopProduct = false;
      // localStorage.removeItem("isClickAuth");
      this.handleGoLink(this.product);
    },
    //点击关闭弹窗
    reCloseBtnDidAction() {
      this.showPopProduct = false;
      if (this.isClickSubmit) {
        this.submitFormNextGoPage();
      } else {
        this.showAlertView();
      }
    },
    showAlertView() {
      Dialog.confirm({
        title: "确认退出",
        message: "您还有一步就可完成申请\n是否确认退出?",
        confirmButtonText: "继续填写",
        cancelButtonText: "确认退出",
        confirmButtonColor: "#3366FF",
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          this.showPopProduct = true;
        });
    },
    requestPopupProduct() {
      API_Common.getPopupProductList(this.form.channelNo).then((res) => {
        if (res.code == 200) {
          var total = res.data.total;
          var list = res.data.list;
          console.log(res);
          if (total > 0) {
            this.product = list[0];
            this.product.loanRate = this.product.loanRate + "%";
            this.showBackBtn = true;
          }
        }
      });
    },
    handleProtocol(pageType) {
      window.location.href = "/protocol.html?pageType=" + pageType;
      localStorage.setItem("isClickAuth", "YES");
    },
    backBtnDidAction() {
      if (this.product.productName) {
        this.showPopProduct = true;
      }
    },
    handleGoLink(item) {
      API_Common.buried({
        productId: item.productId,
        showPage: 5,
      }).then((res) => {
        if (200 === res.code) {
          setTimeout(function () {
            window.location.href = item.linkUrl;
          }, 500);
        } else {
          window.location.href = item.linkUrl;
        }
      });
    },

    //埋点 监听停留时间
    handleUserResidenceTime() {
      var that = this;
      // eslint-disable-next-line no-unused-vars
      that.timerUpdate = setTimeout(function () {
        API_Common.delayModel8SUpdate(that.source, 2, that.tid, 2);
        that.timerUpdate5 = setTimeout(function () {
          API_Common.delayModel8SUpdate(that.source, 5, that.tid, 2);
          that.timerUpdate10 = setTimeout(function () {
            API_Common.delayModel8SUpdate(that.source, 10, that.tid, 2);
            that.timerUpdate30 = setTimeout(function () {
              API_Common.delayModel8SUpdate(that.source, 30, that.tid, 2);
              that.timerUpdate60 = setTimeout(function () {
                API_Common.delayModel8SUpdate(that.source, 60, that.tid, 2);
              }, 30000);
            }, 20000);
          }, 5000);
        }, 3000);
      }, 2000);
    },
  },
};
</script>

<style scoped>
@import "../../css/popupProduct.css"; /*引入公共样式*/

iframe {
  display: block;
}
</style>

<style lang="scss" scoped>
.container {
  height: 100vh;
  overflow: auto;
  background: url("../../assets/images/bule_bg.png") no-repeat top left;

  background-size: 100%;
  padding: 0.3rem 0.35rem;

  .van-overlay {
    z-index: 3;
  }
}

.headerView {
  padding-bottom: 0.3rem;
}

.address {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.3rem;
  font-size: 0.2rem;
  font-weight: 400;
  color: #3a3a3a;
  padding: 0.1rem;
}

.icon-position {
  width: 0.18rem;
  height: 0.22rem;
  margin-right: 0.04rem;
}
.progress {
  margin-top: 0.7rem;
}

.text-wrapper_5 {
  width: 100%;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  margin-top: 0.7rem;
}
.text-wrapper_6 {
  width: 100%;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  margin-top: 0.7rem;
  margin-bottom: 0.3rem;
}
.bottom_view {
  width: 100%;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  margin-left: 10px;
}
.text_7 {
  width: 210px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.paragraph_1 {
  overflow-wrap: break-word;
  color: #666666;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: normal;
  margin-top: 0.2rem;
}

.step-line-move {
  transform: rotate(157deg);
  transition: transform 2s ease-in-out;
}

.scale {
  top: 0;
  left: 0;
  width: 6.64rem;
  height: 6.64rem;
}
.assetView {
  padding-bottom: 0.3rem;
  font-size: 0px;
}
.virtualAssetView {
  padding-bottom: 0.3rem;
  font-size: 0px;
}

.text_13 {
  height: 18px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  font-weight: 500;
  white-space: nowrap;
}

.logo {
  width: 1.2rem;
  height: 0.28rem;
  margin-bottom: 0.2rem;
}

.register-form {
  margin-top: 0.5rem;
}

.inp-elem-box {
  margin-bottom: 0.3rem;
  border-radius: 0.08rem;
  margin-top: 0.3rem;
}
.inp-elem {
  padding: 0.24rem 0.26rem;
  background-color: #f9f9f9;
}

.van-hairline--top-bottom::after {
  display: none;
}

.label-left {
  font-size: 0.28rem;
  font-weight: 400;
  color: #3a3a3a;

  > span {
    color: #c3c2c6;
  }

  > small {
    display: block;
    margin: 0.1rem 0;
  }

  .highlight {
    color: #ff7f35;
  }
}

.tags {
  font-size: 16px;
  color: #333333;
}

.van-col {
  > div {
    height: 0.9rem;
    width: 100%;
    margin-top: 0.3rem;
    background: #ecece7;
    border: 0.02rem solid #ecece7;
    border-radius: 0.06rem;
  }
}

.tags .active {
  > div {
    background: #f3f7ff;
    border-color: #0066ff;
    color: #0066ff;
  }
}

.btn-submit {
  margin: 0.3rem 0;
  display: block;
  width: 100%;
  height: 0.9rem;
  line-height: 0.9rem;
  background-color: #0065ff;
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  border-radius: 0.08rem;
}

.btn-submit-tips {
  font-size: 0.24rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #8884e9;

  > p {
    margin-left: 0.05rem;
  }
}

.bottom {
  margin: 0.3rem -0.1rem 0;
  font-family: PingFangSC, PingFangSC-Regular;
  font-size: 0.24rem;
  font-weight: 400;
  text-align: center;
  color: #8884e9;
  line-height: 0.4rem;
  display: block;
  white-space: pre-line;
}

.toutiao-show-con {
  padding: 0 0.4rem;
  height: 100%;
  overflow: hidden;
}

.toutiao-pup-title {
  margin: 0.4rem 0 0.24rem;
  font-size: 0.26rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #959595;
  text-align: justify;

  > span {
    color: #f88b2d;
  }
}

.toutiao-pup-protocol {
  height: calc(100% - 3.5rem);
  overflow: auto;

  > div {
    height: 45%;
    background: #f7f7f7;
    border-radius: 0.24rem;
    padding: 0.26rem;
    margin-bottom: 0.2rem;
    overflow: hidden;
    transition: height 1s linear;
  }

  > div.expand-height {
    height: auto;
  }

  h4 {
    margin-bottom: 0.2rem;
    font-size: 0.3rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    color: #3c3e41;
  }

  .islink {
    padding: 0.05rem;
    right: 0;
    bottom: 0;
    width: 1.7rem;
    box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.3);
  }
}

.go-on {
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.8rem;
  background: #ffffff;
  box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.3);
  padding: 0.16rem 0.4rem 0.68rem;
  font-size: 0.3rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ffffff;

  > p {
    line-height: 0.96rem;
    background: linear-gradient(90deg, #ffbd56, #ff7e34);
  }
}

.show-protocol-con {
  height: 100%;
  padding: 0.4rem 0.4rem 0;

  > h4 {
    margin-bottom: 0.2rem;
    font-size: 0.3rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    color: #3c3e41;
  }
}

.show-protocol-info {
  height: calc(100% - 1.45rem);
  overflow: auto;
}

.show-protocol-btn {
  left: 0;
  right: 0;
  border-top: 0.02rem solid #e3e3e3;
  font-size: 0.3rem;
  height: 0.8rem;
  line-height: 0.8rem;
  margin: 0 -0.4rem;
  font-family: PingFangSC, PingFangSC-Regular;
}
.image-text_1 {
  height: 15px;
  display: block;
}

.section_1 {
  position: relative;
  width: 8.22rem;
  height: 4.94rem;
}
.image-text_1 {
  width: 1.72rem;
  height: 0.46rem;
  margin: 0.4rem 0 0 0.76rem;
}
.label_1 {
  width: 0.44rem;
  height: 0.44rem;
  margin-top: 0.02rem;
}

.text-group_2 {
  width: 2.4rem;
  height: 1.78rem;
  margin: 0.66rem 0 0 2.8rem;
}
-text_3 {
  width: 5.64rem;
  height: 0.32rem;
  margin: 0.24rem 0 0.54rem 0.28rem;
}
-text_4 {
  width: 3.04rem;
  height: 0.9rem;
}
.block_1 {
  width: 6.8rem;
  height: 0.76rem;
  margin: 0.36rem 0 0.52rem 0.6rem;
}
.tag_1 {
  background-color: rgba(255, 255, 255, 0.17);
  border-radius: 5px;
  height: 0.76rem;
  border: 1px solid rgba(255, 255, 255, 0.32);
  width: 2rem;
}
-text_5 {
  width: 3.04rem;
  height: 0.9rem;
  margin-top: 0.04rem;
}
.text-wrapper_1 {
  background-color: rgba(255, 255, 255, 0.17);
  border-radius: 5px;
  height: 0.76rem;
  border: 1px solid rgba(255, 255, 255, 0.32);
  margin-left: 0.4rem;
  width: 2rem;
}
-text_6 {
  width: 3.04rem;
  height: 0.9rem;
}
.button_1 {
  background-color: rgba(255, 255, 255, 0.17);
  border-radius: 5px;
  height: 0.76rem;
  border: 1px solid rgba(255, 255, 255, 0.32);
  margin-left: 0.4rem;
  width: 2rem;
}
-text_7 {
  width: 3.04rem;
  height: 0.9rem;
}
.text-wrapper_2 {
  height: 3.2rem;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnged624d8ef9b49af317e25d169e387a5d998f250bc1623053ca4daf536ec900c9)
    0rem 0rem no-repeat;
  background-size: 2.24rem 3.56rem;
  width: 3.44rem;
  position: absolute;
  left: 5.5rem;
  top: 0.46rem;
}
.text_8 {
  width: 1.68rem;
  height: 0.34rem;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 0.4rem;
  text-align: right;
  white-space: nowrap;
  line-height: 0.34rem;
  margin-left: -0.2rem;
}
.bottom_icon {
  width: 12px;
  height: 15px;
  background-size: cover;
}

.text-group_1 {
  width: 276px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 15px;
}
::v-deep {
  .van-field__label {
    font-family: PingFangSC, PingFangSC-Medium;
    font-size: 16px;
    color: #000000;
  }
  .van-progress__pivot {
    font-size: 12px;
  }
}
</style>
